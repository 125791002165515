<template>
  <div class="report-container">
    <div class="report-title-box">修改资料</div>
    <div class="divider"></div>
    <div class="report-content">
      <div class="report-box">
        <el-form
          :model="dataPO"
          :rules="rules"
          ref="form"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-row>
            <el-col :span="22">
              <el-form-item label="" prop="pic" class="pic-item">
                <div class="img-box">
                  <img
                    :src="dataPO.imgUrl"
                    alt=""
                    style="
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      display: inline-block;
                    "
                  />
                  <div class="mask" @click="handleChangePic">修改头像</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="姓名" prop="consumerName">
                <el-input
                  v-model="dataPO.consumerName"
                  placeholder="请输入姓名"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="性别" prop="consumerSex" class="">
                <el-radio-group v-model="dataPO.consumerSex">
                  <el-radio
                    v-for="(item, index) in genderList"
                    :key="index"
                    :label="item.value"
                    :value="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-if="dataPO.consumerType == '60461002'">
              <el-form-item label="企业名称" prop="corpname">
                <el-input
                  v-model.trim="dataPO.corpname"
                  placeholder="请输入企业名称"
                  maxlength="200"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-if="dataPO.consumerType == '60461002'">
              <el-form-item label="统一社会信用代码" prop="corpcode">
                <el-input
                  v-model.trim="dataPO.corpcode"
                  placeholder="请输入统一社会信用代码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-if="dataPO.consumerType == '60461002'">
              <el-form-item label="法人姓名" prop="legalPerson">
                <el-input
                  v-model.trim="dataPO.legalPerson"
                  placeholder="请输入法人姓名"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20" class="phone-style">
              <el-form-item label="联系电话" prop="phone">
                <el-input
                  v-model="dataPO.phone"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @blur="dataPO.phone = $event.target.value"
                  placeholder="请输入联系电话"
                  disabled
                ></el-input>
                <span class="edit-style" @click="handleChangePhone">修改</span>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="邮箱" prop="mailbox">
                <el-input
                  v-model.trim="dataPO.mailbox"
                  placeholder="请输入邮箱"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item label="地址" prop="consumerAddress">
                <el-input
                  v-model.trim="dataPO.consumerAddress"
                  placeholder="请输入地址"
                  maxlength="200"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item class="btn-item">
                <el-button type="primary" @click="submitForm('form')">保存</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <!-- 弹窗dialog -->
    <Modal :visible.sync="visible" class="dialog-style">
      <div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">
        上传头像
      </div>
      <div class="pic-box">
        <div class="pic-item">
          <img :src="this.dataPO.imgUrl" alt="" width="100%" height="100%" />
        </div>
        <el-upload
          ref="uploadPic"
          class="upload-demo upload-pic"
          :action="uploadImgUrl"
          :headers="headerObj"
          :show-file-list="false"
          :before-upload="beforePicUpload"
          :on-success="handleAvatarSuccess"
          accept="image/*"
        >
          <div>重新上传</div>
        </el-upload>
        <!-- <el-button type="primary" @click="submitPic">确认</el-button> -->
      </div>
    </Modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Modal from "@/components/Modal";
import { validateSpecialKey } from "@/utils/validate.js";
export default {
  components: {
    Pagination,
    Empty,
    Modal,
  },
  data() {
    // 手机固话校验
    const validatePhone = (rule, value, callback) => {
      const moblie =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
      const tel = /^(\d{3,4}-)?\d{7,8}$/;
      if (!new RegExp(moblie).test(value) && !tel.test(value) && value !== "") {
        callback(new Error("请输入格式正确的联系方式"));
      } else {
        callback();
      }
    };
    // 邮箱校验
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback();
      } else if (
        !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value)
      ) {
        callback(new Error("请输入格式正确的邮箱"));
      } else {
        callback();
      }
    };
    // 统一社会信用代码校验
    const validateCode = (rule, value, callback) => {
      const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的统一社会信用代码"));
      }
    };
    return {
      visible: false,
      dataPO: {},
      uploadImgUrl: process.env.VUE_APP_BASE_URL + "/mds/api/file/upload", // 上传图片服务器地址
      headerObj: {
        Authorization: this.$session.getLoginToken(),
      },
      genderList: [
        { label: "男", value: "300201" },
        { label: "女", value: "300202" },
        { label: "未知", value: "300203" },
      ],
      rules: {
        imgUrl: [{ required: true, message: "请上传头像", trigger: "change" }],
        consumerName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        consumerSex: [{ required: true, message: "请选择性别", trigger: "change" }],
        mailbox: [
          { trigger: "blur" },
          {
            validator: validateEmail,
          },
        ],
        corpname: [
          { trigger: "blur" },
          {
            validator: validateSpecialKey,
          },
        ],
        // phone: [
        //   {
        //     required: false,
        //     message: "请输入正确的手机号或联系电话",
        //     trigger: "change"
        //   },
        //   {
        //     validator: validatePhone
        //   }
        // ],
        corpcode: [{ validator: validateCode, trigger: "blur" }],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    // 获取列表
    getInfo() {
      this.$api.personalCenter.getInfo().then((res) => {
        let user = res.data;
        if (!user.imgUrl) {
          user.imgUrl = require("../../../../assets/img/user/user-img.png");
        }
        this.dataPO = user;
        this.dataPO.phone = this.$utils.formattedPhone(user.phone);
        if (user.consumerType == "60461002") {
          this.rules.corpname = [
            { required: true, message: "请输入企业名称", trigger: "blur" },
            {
              validator: validateSpecialKey,
            },
          ];
        }
      });
    },
    // 修改手机号按钮跳转
    handleChangePhone() {
      this.$router.push({
        path: "/user/my-center/modify-phone",
        query: {
          flag: "modify",
        },
      });
    },
    // 修改头像按钮
    handleChangePic() {
      this.visible = true;
    },
    // 图片上传事件
    handleAvatarSuccess(res, file, filer) {
      if (res.code == 200) {
        this.$message.success("头像上传成功，点击保存按钮后头像生效");
        this.dataPO.headPortraitId = res.data.fileId;
        this.dataPO.imgUrl = res.data.url;
        this.visible = false;
        this.$forceUpdate();
      } else {
        this.$message.error("上传失败");
      }
    },
    beforePicUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过5M!");
      }
      return isLt5M;
    },
    // 提交修改图片
    submitPic() {},
    // 表单提交事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.dataPO);
          this.$api.personalCenter
            .update({
              ...this.dataPO,
              headPortraitId: this.dataPO.headPortraitId,
              consumerId: this.dataPO.consumerId,
              account: this.dataPO.phone,
              accountState: this.dataPO.consumerType,
            })
            .then((res) => {
              this.$message.success("提交成功");
              this.$api.personalCenter.getInfo().then((res) => {
                this.$session.setUsers(res.data);
                let user = res.data;
                if (!user.imgUrl) {
                  user.imgUrl = require("../../../../assets/img/user/user-img.png");
                }
                this.dataPO = user;
              });
            })
            .catch((err) => {
              this.$message.error("提交失败");
            });
        } else {
          this.$message.error("提交失败!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
